@import "src/styles/variables";

.attachment-upload {

}

.attachment-upload__attachment-wrapper {
  position: relative;
  margin-top: 1rem;
}

.ant-btn.ant-btn-icon-only.attachment-upload__close-btn {
  position: absolute;
  top: -15px;
  left: 175px;
  background-color: $danger-color;
  border-radius: 50em;
  border: 1px solid $danger-color;
  padding: 5px 10px;
  height: auto;
  width: auto;
  .anticon {
    width: 14px;
    height: 14px;
  }
  svg {
    color: $white;
    width: 14px;
    height: 14px;
    font-size: 14px;
  }
}

.attachment-upload__input {
  width: 100%;
  padding: 2rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  background-color: $light-grey;
  border: 1px dashed $primary-border;
  margin-top: 1rem;
  .ant-spin.ant-spin-lg.ant-spin-spinning.app-loader {
    margin-top: 0;
    .ant-spin-dot {
     font-size: 2rem;
    }
  }
}

.attachment-upload__icon {
  display: block;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.attachment-upload__img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 5px;
}

.attachment-upload__title {
  margin: 0 0 6px 0;
  color: $secondary-black;
  font-weight: 500;
}

@import "src/styles/variables";

.ba-agreement-response {
  width: 80%;
  margin: 0 auto;
  box-shadow: $primary-shadow;
  padding: 3rem;
  background-color: $white;
  border-radius: 8px;
  text-align: center;
}

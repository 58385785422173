@import "src/styles/variables";

.ant-btn {
  padding: 7px 25px;
  height: auto;
  border-radius: 8px;
  font-size: 1rem;
  &.ant-btn-primary {
    color: $white;
    background-color: $primary-color;
    border: 1px solid $primary-color;
    &:disabled,
    &[disabled] {
      color: $white;
      border: 1px solid $primary-color;
      background-color: $primary-color;
      opacity: 0.8;
    }
  }
  &.ant-btn-link {
   color: $primary-color;
    padding: 0 3px;
  }
  &.secondary-btn {
    color: $primary-color;
    background-color: $secondary-color;
    border: 1px solid $secondary-color;
    &:hover {
      border: 1px solid $secondary-color;
    }
    &:disabled,
    &[disabled] {
      color: $primary-color;
      border: 1px solid $secondary-color;
      background-color: $secondary-color;
      opacity: 0.8;
    }
  }
  &.ant-btn-icon-only {
    padding: 5px;
  }
}


/* Modal styles */
.ant-modal {
  border-radius: 8px;
  font-size: 1rem;
  .ant-modal-content {
    border-radius: 8px;
  }
  .ant-modal-body {
    border-radius: 8px;
    font-size: 1rem;
    padding: 3rem;
  }
  @media (max-width: 768px) {
    width: 90% !important;
    .ant-modal-body {
      padding: 2rem;
    }
  }
}


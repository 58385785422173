@import "src/styles/variables";

.verify-pan-form {
  border-radius: 8px;
  background-color: $white;
  padding: 3rem;
}

.verify-pan-form__submit {
  text-align: right;
}

.verify-pan-form__verified, .verify-pan-form__failed {
  position: absolute;
  top: 43px;
  right: 10px;
  .verify-pan-form__verified-icon, .verify-pan-form__failed-icon {
    border: 3px solid $success-color;
    border-radius: 50em;
    padding: 3px;
    svg {
      color: $success-color;
    }
  }
  .verify-pan-form__failed-icon {
    border: 3px solid $danger-color;
    svg {
      color: $danger-color;
    }
  }
}

@media (max-width: 768px) {
  .verify-pan-form {
    padding: 2rem;
  }
}

@import "src/styles/variables";

.auth-wrapper {
  margin: -4rem;
  width: calc(100% + 8rem);
  margin-top: calc(-4rem - 65px);
}

.auth-wrapper__logo {
  width: 250px;
  margin-top: 45vh;
}

.auth-wrapper__content {
  height: 110vh;
}

.auth-wrapper__logo-wrapper {
  background-color: $primary-color;
  height: 100%;
  overflow: hidden;
  text-align: center;
}

.auth-wrapper__form-wrapper {
  display: flex;
  align-content: center;
  justify-content: center;
}

.auth-wrapper__mobile-logo-wrapper {
  display: none;
}

@media (max-width: 768px) {

  .auth-wrapper {

    .auth-wrapper__form-wrapper {
      display: block;
    }

    .auth-wrapper__mobile-logo-wrapper {
      display: block;
    }

    .auth-wrapper__logo {
      display: block;
      margin: 0 auto;
      padding-top: 4rem;
    }

    margin: 0;
    width: 100%;
    .auth-wrapper__logo-content {
      display: none;
    }

    .auth-wrapper__form-wrapper {
      background-color: $primary-color;
      min-height: 100vh;
    }
  }

  .auth-wrapper__content {
    height: auto;
  }

}

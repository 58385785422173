@import "src/styles/variables";

.ba-reference-form {
  padding: 3rem;
  box-shadow: $primary-shadow;
  background-color: $white;
  border-radius: 8px;
}

.ant-btn.ant-btn-icon-only.ba-reference-form__close-icon {
  padding: 5px 10px;
  border-radius: 50em;
  background-color: $danger-color;
  border: 1px solid $danger-color;
  margin-top: 2rem;
  width: auto;
  svg {
    font-size: 15px;
    color: $white;
  }
}

.ant-btn.ba-reference-form__remove-btn {
  display: none;
}

@media (max-width: 768px) {
  .ba-reference-form {
    padding: 2rem;
  }

  .ant-btn.delete-btn.ba-reference-form__remove-btn {
    display: inline;
    margin-bottom: 1rem;
    background-color: $danger-color;
    border: 1px solid $danger-color;
    color: $white;
  }

  .ant-btn.ant-btn-icon-only.ba-reference-form__close-icon {
    display: none;
  }
}

@import "src/styles/variables";

.register-form {
  h1 {
    color: $primary-color;
    span {
      font-size: 1rem;
      color: $secondary-black;
      font-weight: normal;
    }
  }
  margin-top: 6%;
  width: 75%;
  background-color: $white;
  padding: 3rem 4rem;
  box-shadow: $primary-shadow;
  border-radius: 8px;

  .ant-btn {
    width: 100%;
  }
}

.register-form__login-wrapper {
  margin-top: 0.5rem;
  a {
    font-weight: bold;
    color: $primary-color;
    &:hover {
      color: $primary-color;
    }
    margin-left: 3px;
  }
}

.login-form__forgot-password {
  margin-bottom: 1rem;
  a {
    color: $secondary-black;
    &:hover {
      color: $primary-color;
    }
  }
}

@media (max-width: 768px) {
  .register-form {
    margin: 6% auto 0 auto;
    padding: 2rem;
    width: 90%;
  }
}

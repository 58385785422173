@import "src/styles/variables";

.ba-personal-details {

}

.ba-personal-details__form-wrapper {
  margin-top: 1rem;
  padding: 3rem;
  background-color: $white;
  box-shadow: $primary-shadow;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .ba-personal-details__form-wrapper {
    padding: 2rem;
  }
}

@import "src/styles/variables";

.ba-onboarding-wrapper {
  h1 {
    color: $primary-color;
  }
  button.ant-btn.ba-onboarding-wrapper__back-btn {
    padding: 0;
    h3 {
      color: $primary-color;
    }
    svg {
      color: $primary-color;
    }
  }
}
.ant-modal-content {
  .ba-onboarding-wrapper__modal-text {
    text-align: center;
    font-weight: bold;
  }
  .ant-modal-body {
    padding: 3.5rem 0 1rem 0 !important;
  }
  .ant-modal-footer {
    border: none;
    text-align: center;
    padding-bottom: 3rem !important;
    .ant-btn {
      margin-left: 1rem;
      padding: 5px 25px;
    }
  }
}

.ba-onboarding-wrapper__checklist-wrapper__progress-title {
  margin-bottom: 0.5rem;
}

.ant-progress.ant-progress-line.ba-onboarding-wrapper__progress {
  .ant-progress-inner {
    border-radius: 8px;
    box-shadow: $primary-shadow;
  }
  .ant-progress-success-bg,
  .ant-progress-bg {
    background-color: $primary-color;
    padding: 15px;
    border-radius: 8px 0 0 8px;
  }
}

.ba-onboarding-wrapper__content {
  margin-top: 1.3rem;
}

@media (max-width: 768px) {
  .ba-onboarding-wrapper {
    margin: 5rem 2rem;
  }
  .ba-onboarding-wrapper__checklist-wrapper {
    display: none;
  }
}

@import "src/styles/variables";

.verify-aadhaar-form {
 position: relative;
 
}

.verify-aadhaar-form__submit {
 text-align: right;
}

.verify-aadhaar-form__modal {
 text-align: center;
 p {
  color: $secondary-black;
 }
 .ant-btn.ant-btn-link {
     span {
         font-size: 18px;
        font-weight: bold;
        text-decoration: underline;
     }
}
}

.verify-aadhaar-form__otp-wrapper {
 display: flex;
 align-items: center;
 justify-content: center;
 input {
  color: $secondary-black;
  font-size: 1.5rem;
  width: 2rem !important;
  border: none;
  border-bottom: 1px solid $primary-border;
  font-weight: bold;
  &:focus {
   outline: none;
  }
 }
 span {
  opacity: 0;
  width: 15px;
 }
}

.verify-aadhaar-form__otp-submit {
 margin-top: 2rem;
 .ant-btn-primary {
  width: 100%;
 }
}

.verify-aadhaar-form__resend-wrapper {
 margin-top: 1rem;
}

.verify-aadhaar-form__note {
 margin-top: 2rem;
}

.ant-modal.verify-aadhaar-form__success-modal {
 width: 60%;
 .ant-modal-body {
  padding: 2rem 3rem;
 }
 .ant-modal-confirm-btns {
  display: none;
 }
}

.verify-aadhaar-form__success-icon {
 background-color: $primary-color;
 padding: 1rem;
 border-radius: 50em;
 margin-bottom: 1rem;
 svg {
  color: $white;
  font-size: 1.2rem;
 }
}

.verify-aadhaar-form__verified, .verify-aadhaar-form__failed {
 position: absolute;
 top: 43px;
 right: 10px;
 .verify-aadhaar-form__verified-icon, .verify-aadhaar-form__failed-icon {
  border: 3px solid $success-color;
  border-radius: 50em;
  padding: 3px;
  svg {
   color: $success-color;
  }
 }
 .verify-aadhaar-form__failed-icon {
  border: 3px solid $danger-color;
  svg {
   color: $danger-color;
  }
 }
}

.verify-aadhaar-form__failure-modal {
 h2 {
  color: $danger-color;
 }
 .verify-aadhaar-form__failure-icon {
  background-color: $danger-color;
  padding: 1rem;
  border-radius: 50em;
  margin-bottom: 1rem;
  svg {
   color: $white;
   font-size: 1.2rem;
  }
 }
}



@import "src/styles/variables";

.ba-agreement-form {
  width: 80%;
  margin: 0 auto;
  box-shadow: $primary-shadow;
  padding: 3rem;
  background-color: $white;
  border-radius: 8px;
  text-align: center;
}

.ba-agreement-form__success-icon {
  background-color: $primary-color;
  padding: 1rem;
  border-radius: 50em;
  margin-bottom: 1rem;
  svg {
    color: $white;
    font-size: 1.2rem;
  }
}

.ant-modal-confirm.ba-agreement-form__success-modal {
  .ant-modal-confirm-btns {
    float: none;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .ba-agreement-form {
    width: 90%;
    margin-top: 10rem;
  }
}

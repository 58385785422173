@import "src/styles/variables";

.ba-profile-picture-form {
  padding: 3rem;
  box-shadow: $primary-shadow;
  background-color: $white;
  border-radius: 8px;
  h2 {
    margin-top: 4rem;
  }
}

.ba-profile-picture-form__success-icon {
  background-color: $primary-color;
  padding: 1rem;
  border-radius: 50em;
  margin-bottom: 1rem;
  svg {
    color: $white;
    font-size: 1.2rem;
  }
}

.ant-modal-confirm.ba-profile-picture-form__success-modal {
  .ant-modal-confirm-btns {
    float: none;
    text-align: center;
  }
}

.ba-profile-picture-form__modal {
  video {
    width: 100%;
    height: 350px;
  }
}

@media (max-width: 768px) {
  .ba-profile-picture-form {
    padding: 2rem;
    h2 {
      text-align: center;
      margin-top: 0;
    }
  }
}

@import "src/styles/variables";

.ant-menu.app-header.ant-menu-horizontal {
  background-color: $primary-color;
  color: $white;
  padding: 10px;
  font-size: 1rem;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  .ant-menu-item.navbar-item.ant-menu-item-selected,
  .ant-menu-item.navbar-item.ant-menu-item-active {
    border-bottom: 2px solid transparent;
    color: $white;
  }
}

.app-header__logo {
  width: 120px;
}

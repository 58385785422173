
/* Color palette */
$primary-color: #363A7C;
$secondary-color: #F3B926;
$white: #FFFFFF;
$light-white: #FFFFFF40;
$primary-light-color: #0D494E10;
$primary-black: #4B4B4B;
$secondary-black: #4F4F4F;
$primary-border: #bebebe;
$dark-blue: #22509D;
$light-grey: #F5F5F5;
$dark-grey: #8898AA;
$success-color: #26AD10;
$danger-color: #FF503C;
$warning-color: #F3B21B;
$body-bg: #F2F2E5;
$table-bg: #F7FAFC;
$input-bg: #F8FBFC;
$timeline-border: #C9C9C9;

/* Shadows */
$primary-shadow: 0px 12px 24px #00000014;
$secondary-shadow: 0px 3px 6px #2C28281C;


/* Spacer variables */
$spacer-x : 1rem;
$spacer-y : 1rem;

@import "src/styles/variables";

.verify-ba-details {
  background-color: $white;
  padding: 3rem;
  box-shadow: $primary-shadow;
  border-radius: 8px;
}


@media (max-width: 768px) {
  .verify-ba-details {
    padding: 2rem;
  }
}

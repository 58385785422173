@import "src/styles/variables";

.ba-onboarding-checklist {
  margin-top: 2rem;
  width: 100%;
  padding: 2rem;
  background-color: $white;
  box-shadow: $primary-shadow;
  border-radius: 8px;
}

.ba-onboarding-checklist__title {
  color: $primary-color;
  font-weight: bold;
  margin-bottom: 1rem;
}

.ant-timeline.ba-onboarding-checklist__timeline {
  .ant-timeline-item-head-blue {
    border-color: $timeline-border;
  }
  >.ant-timeline-item {
    padding-bottom: 30px;
    min-height: 30px;
    font-size: 1rem;
    font-family: SFProDisplaySemibold, sans-serif;
    &.step-active {
      .ant-timeline-item-head.ant-timeline-item-head-blue {
        background-color: $success-color;
        border: 2px solid $success-color;
      }
    }
    &:last-child {
      padding-bottom: 0;
    }
  }
  .ant-timeline-item-head {
    width: 30px;
    height: 30px;
  }
  .ant-timeline-item-content {
    margin-left: 40px;
    top: 5px;
  }
  .ant-timeline-item-tail {
    left: 14px;
    border-left: 2px solid $timeline-border;
  }

  .anticon.ba-onboarding-checklist__check-icon {
    position: absolute;
    color: $white;
    top: 3px;
    left: -33px;
  }
}
